<template>
	<phi-page :loading="$store.state.api.isLoading" color="#f2f2f2">
		<div slot="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1>{{ $t("noun.billing") }}</h1>
		</div>

		<phi-tabs v-model="currentView">
            <phi-tab :value="1" :label="$t('noun.pendingCharges')" />
            <phi-tab :value="2" :label="$t('noun.recentPayments')" />
            <phi-tab :value="3" :label="$t('noun.paymentsToApply')" />
        </phi-tabs>

		<!-- pendings -->
		<div>
			<div v-if="currentView == 1">
				<router-link
					v-for="debit in pendings"
					:key="debit.id"
					:to="{name: 'billing-debit-debitId', params:{debitId: debit.id}}"
					class="block"
					>
					<div class="icon"><img :src="debit.type == 2 ? optional : icono"></div>
					<div class="details">
						<div class="title">{{ debit.period.name }} {{ debit.sequence ? '#' + debit.sequence : null }}</div>
						<div class="name">{{ debit.person|person }}</div>
						<div :class="['date', debit.expiration_date < dateNow ? 'expired' : '']" v-if="debit.expiration_date"><i class="material-icons">event</i> {{ $date(debit.expiration_date) }}</div>
					</div>
					<div class="price">
						{{ debit.balance|currency }}
						<p class="interest" v-if="debit.interests.value">{{ debit.interests.value|currency }}</p>
					</div>
				</router-link>
			</div>
		</div>

		<!-- pagos recientes -->
		<div>
			<div v-if="currentView == 2">
				<router-link
					v-for="debit in recent"
					:key="debit.id"
					:to="{name: 'billing-credit-creditId', params:{creditId: debit.id}}"
					class="block"
				>
					<div class="icon"><img :src="debit.type == '0' ? recientesIcon : notasIcon"></div>
					<div class="details">
						<div class="title">{{ debit.method.name }} {{ debit.sequence ? '#' + debit.sequence : null }}</div>
						<!-- <div class="name">{{ debit.person|person }}</div> -->
						<div class="date" v-if="debit.date"><i class="material-icons">event</i> {{ $date(debit.date) }}</div>
					</div>
					<div class="price">{{ debit.value|currency }}</div>
				</router-link>
			</div>
		</div>

		<!-- pagos por aplicar -->
		<div>
			<div v-if="currentView == 3">
				<router-link
					v-for="debit in unapplied"
					:key="debit.id"
					:to="{name: 'billing-credit-creditId', params:{creditId: debit.id}}"
					class="block"
				>
					<div class="icon"><img :src="debit.type == '0' ? aplicarIcon : notasIcon"></div>
					<div class="details">
						<div class="title">{{ debit.method.name }} {{ debit.sequence ? '#' + debit.sequence : null }}</div>
						<div class="name">{{ debit.person|person }}</div>
						<div class="date" v-if="debit.date"><i class="material-icons">event</i> {{ $date(debit.date) }}</div>
					</div>
					<div class="price">{{ debit.value|currency }}</div>
				</router-link>
			</div>
		</div>

	</phi-page>
</template>

<script>
import cobrosIcon from './img/cobros.png';
import aplicarIcon from './img/aplicar.png';
import notasIcon from './img/aplicar_notas.png';
import recientesIcon from './img/recientes.png';
import icono from './img/Icono.png';
import optional from './img/optional.png';

import billingIcon from './img/billing.png';

// tabs
import PhiTabs from '@/components/Phi/Tabs.vue';
import PhiTab from '@/components/Phi/Tab.vue';

export default {
	components: {PhiTabs, PhiTab},

	data() {
		return {
			billingIcon,
			cobrosIcon,
			aplicarIcon,
			notasIcon,
			recientesIcon,
			icono,
			optional,
			pendings:  	[],
			recent: 	[],
			unapplied: 	[],
			personId: 	this.$route.params.personId,
			currentView: 1,
			dateNow: Math.floor(Date.now() / 1000)
		}
	},

	mounted() {
		this.currentView = this.$store.state.stateBilling.currentTab;

		// pendings
		this.$store.state.api.get(`v3/people/${this.personId}/billing/debits/pending`)
		.then(data => {this.pendings = data});

		// recent
		this.$store.state.api.get(`v3/people/${this.personId}/billing/credits`)
		.then(data => {this.recent = data});

		// unapplied
		this.$store.state.api.get(`v3/people/${this.personId}/billing/credits?balance=true`)
		.then(data => {this.unapplied = data});
	},

	filters: {
		person(val) {
			if (val && val.firstname) {
				return val.firstname + " " + val.lastname;
			}

			return null;
		}
	},

	watch: {
		currentView(val) {
			this.$store.commit('setBillingTab', val);
		}
	}
}
</script>

<style lang="scss" scoped>
.block {
	width: 100%;
	padding: 15px;
	background: #fff;
	border-radius: 10px;
	margin-bottom: 10px;

	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.icon {
		width: 40px;
		height: 40px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.details {
		flex-grow: 1;
		font-size: 15px;
		padding: 0px 11px;
		line-height: 25px;

		.title {
			font-size: 16px;
			font-weight: bold;
		}

		.name {
			opacity: .6;
		}

		.date {
			color: #414141;
			display: flex;
			align-items: center;

			i {
				font-size: 15px;
				margin-right: 5px;
			}
		}

		.expired {
			color: #EF5350;
		}
	}

	.price {
		width: 130px;
		font-weight: bold;
		text-align: right;
		flex-grow:1;
		padding-top: 5px;

		.interest {
			color: #EF5350;
			margin-top: 7px;
			font-size: 13px;
			padding-top: 5px;
			border-top: 1px solid #666;
		}
	}
}
</style>